.icon {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 75px;
  align-items: center;
  font-size: 13px;
  padding: 3px;
  transition: all 0.3s;
  z-index: 100;
  user-select: none;
  margin-top: 10px;
  cursor: pointer;

  background-color: rgba(117, 190, 218, .0);
  border: 1px solid rgba(255, 255, 255, .0);
}
.icon.selected {
  background-color: rgba(117, 190, 218, .5);
  border: 1px solid rgba(255, 255, 255, .3);
}

.fileIcon {
  transition: all 0.175s;
}
.fileIcon:hover {
  color: rgb(17, 17, 136);
}

.removeContainer {
  color: black;
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: -5px;
  transition: all 0.175s;
}
.removeContainer:hover {
  color: lightcoral;
}

.unSelect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.iconImage {
  font-size: 45px;
}

.whiteText {
  color: aliceblue;
}

.iconTitle {
  text-align: center;
}