.desktop {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.desktopImage {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
}

.unSelect {
  position: absolute;
  top: 0;
  left: 0;
  background: red;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}