.container {
    position: relative;
    z-index: 0;
}

.zIndexHack {
    z-index: 10;
}

.overlay {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2000;
}

.dialog {
    background: white;
    border-radius: 5px;
    padding: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
}
