.loginPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.button {
  cursor: pointer;
}

.desktopImage {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
}

.formWrapper {
  background-color: rgba(200, 200, 200, 0.7);
  border-radius: 3px;
  padding: 15px;
}