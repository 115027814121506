.bigWindow {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 1000;
}

.richTextEditor {
  min-height: 200px;
  overflow: auto;
  max-height: calc(100vh - 110px);
}
