.calcWrapper {
  position: absolute;
  right: 0;
  background: white;
  z-index: 1000;
  transition: all 0.3s;
}

.hide {
  right: -340px;
}