.clockWrapper {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: bold;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.7);
}

.time {
  font-size: 20px;
}

.date {
  font-size: 12px;
}