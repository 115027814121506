.windowHeader {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: aquamarine;
  z-index: 1000;
  user-select: none;
}

.closeBtn {
  position: absolute;
  right: 0;
  transition: all 0.3s;
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.closeBtn:hover {
  background: lightcoral;
}