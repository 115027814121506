.headerBtnWrapper {
  display: flex;
  margin-bottom: 5px;
}

.headerBtn {
  font-size: 12px;
  padding: 0 10px;
  transition: 0.3s all;
  cursor: pointer;
}

.headerBtn:hover {
  background-color: lightblue;
}